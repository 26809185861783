import React from 'react'
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom'
import styles from './App.module.css'
import MenusRoute from './Menus/MenusRoute'
import CheckoutRoute from './Checkout/CheckoutRoute'
import {
  OrderProvider,
  QueryProvider,
  ThemeProvider,
} from '@Pacific-Web-Services/component-library'
import Header from './Header/Header'
import { appTheme } from './appTheme'
//https://github.com/stripe/stripe-js#import-as-a-side-effect
import '@stripe/stripe-js'
import { OrderAccepted } from './Checkout/OrderAccepted'

function AppLayout() {
  return (
    <div className={styles.app}>
      <Header />
      <Outlet />
    </div>
  )
}

function App() {
  return (
    <QueryProvider>
      <ThemeProvider theme={appTheme}>
        <OrderProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<AppLayout />}>
                <Route index element={<MenusRoute />} />
                <Route path="/event" element={<MenusRoute />} />
                <Route path="/holiday" element={<MenusRoute />} />
                <Route path="/easter" element={<MenusRoute />} />
                <Route path="/thanksgiving2024" element={<MenusRoute />} />
                <Route path="/easter2025" element={<MenusRoute />} />
                <Route path="/breakfastsandwiches" element={<MenusRoute />} />
                <Route path="/tacos" element={<MenusRoute />} />
                <Route path="/checkout" element={<CheckoutRoute />} />
                <Route path="/confirm/:uuid" element={<OrderAccepted />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </OrderProvider>
      </ThemeProvider>
    </QueryProvider>
  )
}

export default App
